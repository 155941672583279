// About hero

.about-hero {
  position: relative;
  padding: 270px 0 50px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 105px 0 40px 0; }
  .image {
    position: absolute;
    right: 0;
    top: 195px;
    max-width: 58%;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  h1 {
    max-width: 450px;
    margin-bottom: 90px;
    @media only screen and (max-width: $r-768 - 1) {
      max-width: 100%;
      margin-bottom: 5px; } }
  .wrapper {
    .image {
      display: none;
      margin-bottom: 15px;
      @media only screen and (max-width: $r-768 - 1) {
        display: block;
        position: initial;
        width: 100%;
        max-width: 100%; } } }
  p {
    max-width: 850px; } }

// Team

.team {
  padding: 90px 0 0 0;
  background-image: linear-gradient(to bottom, rgba(0, 238, 255, 0.05), rgba(255, 255, 255, 0.05));
  @media only screen and (max-width: $r-768 - 1) {
    padding: 45px 0; }
  &.Board {
    padding-top: 0;
    background-image: none;
    .wrapper {
      margin-bottom: 0;
      .line {
        display: none; } } }
  .title-secondary {
    text-align: center;
    margin-bottom: 15px; }
  .title-third {
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0.31px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 100px;
    color: #4a90e2;
    @media only screen and (max-width: $r-768 - 1) {
      font-size: 18px;
      margin-bottom: 45px; } }
  .wrapper {
    justify-content: center;
    margin-bottom: 70px;
    .line {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding-top: 112px;
      //border-bottom: dashed 1px $color-blue
      &.start {
        .line-left {
          position: absolute;
          left: 0;
          width: 50%;
          border-bottom: dashed 1px #1283c6;
          animation: line-left 2s ease-in-out; }
        .line-right {
          position: absolute;
          right: 0;
          width: 50%;
          border-bottom: dashed 1px #1283c6;
          animation: line-right 2s ease-in-out; } }
      @media only screen and (max-width: $r-768 - 1) {
        display: none; } }
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 30px;
      &:hover {
        transition: .3s;
        img {
          box-shadow: 40px 40px 80px 0 rgba(0, 0, 0, 0.15); } }
      img {
        margin-bottom: 55px;
        height: 197px;
        object-fit: cover;
        transition: .3s; }
      h3 {
        font-family: 'Raleway', sans-serif;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -1px;
        margin-bottom: 8px;
        color: #373c48; }
      p {
        letter-spacing: -0.5px;
        color: #373c48; } } } }

// Info block

.info-block {
  padding: 140px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 45px 0; }
  .wrapper {
    padding: 40px;
    border-radius: 5px;
    box-shadow: 40px 40px 80px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    @media only screen and (max-width: $r-768 - 1) {
      width: calc(100% - 30px);
      margin: 0 auto; }
    div[class*="col-"] {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        width: 1px;
        height: 100%; }
      &.start {
        &:before {
          border-right: dashed 1px #dbdbdb;
          animation: line-down 2s ease-in-out; } }
      @media only screen and (max-width: $r-768 - 1) {
        border-right: 0; }
      &:last-of-type {
        &:before {
          display: none; }
        .item {
          @media only screen and (max-width: $r-768 - 1) {
            margin-bottom: 0; } } } }
    .item {
      text-align: center;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 75px; }
      img {
        height: 169px;
        object-fit: cover;
        margin-bottom: 35px; }
      h3 {
        font-family: 'Raleway', sans-serif;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: normal;
        text-transform: uppercase;
        margin-bottom: 22px;
        color: #171f52;
        @media only screen and (max-width: $r-992) {
          min-height: 54px; }
        @media only screen and (max-width: $r-768 - 1) {
          min-height: auto; } }
      p {
        max-width: 225px;
        font-size: 16px;
        line-height: 1.63;
        letter-spacing: 0.25px;
        margin: 0 auto 16px auto; } } } }
