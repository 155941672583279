// Colors

$color-blue: #1283c6;
$color-dark-blue: #1c287d;


// Global

html,
body {
  overflow-x: hidden; }

body {
  font-display: optional;
  font-family: 'Raleway', sans-serif!important; }

.container {
  width: 1170px;
  max-width: 100%;
  margin: 0 auto; }

img {
  max-width: 100%; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bebas Neue', cursive; }



.main-title {
  font-size: 75px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 73px;
  letter-spacing: 0.65px;
  color: $color-dark-blue;
  @media only screen and (max-width: $r-768 - 1) {
    font-size: 40px;
    line-height: 42px;
    letter-spacing: normal; } }

.title-secondary {
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 63px;
  letter-spacing: 0.65px;
  text-transform: uppercase;
  color: $color-dark-blue;
  @media only screen and (max-width: $r-768 - 1) {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: normal; } }

p,
ul li {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.28px;
  color: #4a4a4a;
  @media only screen and (max-width: $r-768 - 1) {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal; } }

.btn-blue {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  height: 45px;
  border-radius: 19.8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: $color-blue;
  padding: 0 15px;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.63px;
  text-transform: uppercase;
  outline: none;
  transition: .3s;
  color: white;
  @media only screen and (max-width: $r-768 - 1) {
    min-width: 130px;
    height: 40px;
    font-size: 13px; }
  &:hover {
    transition: .3s;
    text-decoration: none;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: $color-dark-blue;
    color: white; } }

.btn-transparent {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  height: 45px;
  border-radius: 19.8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent;
  border: 2px solid $color-blue;
  padding: 0 15px;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.63px;
  text-transform: uppercase;
  outline: none;
  transition: .3s;
  color: $color-blue;
  @media only screen and (max-width: $r-768 - 1) {
    min-width: 130px;
    height: 40px;
    font-size: 13px; }
  &:hover {
    transition: .3s;
    text-decoration: none;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: $color-dark-blue;
    border-color: $color-dark-blue;
    color: white; } }

// Elementor

.elementor-widget:not(:last-child) {
  margin-bottom: 0!important; }

// Modals

.modal-demo {
  .modal-dialog {
    .modal-content {
      .modal-body {
        display: flex!important;
        justify-content: space-between!important;
        flex-direction: row!important;
        align-items: center;
        .flex {
          display: flex;
          justify-content: space-between;
          @media only screen and (max-width: $r-768 - 1) {
            flex-direction: column; }
          .info {
            width: 100%;
            max-width: 347px;
            margin-right: 180px;
            @media only screen and (max-width: $r-768 - 1) {
              margin-right: 0; }
            h2 {
              margin-bottom: 20px; } }
          .wpcf7 {
            width: 100%;
            max-width: 445px;
            form {
              flex-direction: column!important;
              .wpcf7-form-control-wrap {
                width: 100%; }
              .wpcf7-submit {
                width: 100%;
                margin-bottom: 20px; } } } } } } } }

.modal-contacts,
.modal-demo {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: 0;
    .modal-content {
      min-height: 100vh;
      border: 0;
      border-radius: 0;
      //background-image: linear-gradient(to bottom, #1283c6 -5%, #117ec0 15%, #023160 60%)
      background-image: url("../img/backround_pop_up.jpg");
      background-size: cover;
      .close {
        position: absolute;
        right: 0;
        padding: 30px;
        font-size: 50px;
        font-weight: 400;
        color: white;
        opacity: 1;
        z-index: 1;
        @media only screen and (max-width: $r-768 - 1) {
          padding: 15px; }
        &:hover {
          color: white; } }
      .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 966px;
        margin: 0 auto;
        padding: 50px 15px;
        h3 {
          font-family: 'Raleway', sans-serif!important;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: 0.71px;
          margin-bottom: 5px;
          color: white; }
        h2 {
          max-width: 650px;
          font-size: 75px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 73px;
          letter-spacing: 0.65px;
          margin-bottom: 38px;
          color: white;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 40px;
            line-height: 42px;
            letter-spacing: normal; } }
        p {
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.05;
          letter-spacing: 0.79px;
          color: white;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 16px; } }
        form {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .ajax-loader {
            display: none; }
          .wpcf7-not-valid-tip {
            position: absolute;
            font-size: 12px; }
          .wpcf7-response-output {
            width: 100%;
            margin: 0;
            color: white;
            font-size: 12px;
            border: 1px solid white;
            border-radius: 4.8px; }
          .wpcf7-form-control-wrap {
            width: 46%;
            margin-bottom: 20px;
            @media only screen and (max-width: $r-768 - 1) {
              width: 100%; }
            input[type="text"],
            input[type="email"],
            input[type="tel"],
            input[type="url"],
            textarea {
              width: 100%;
              height: 45px;
              border-radius: 4.8px;
              border: solid 0.5px #979797;
              background-color: white;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: normal;
              padding: 13px 10px;
              outline: none;
              appearance: none;
              resize: none;
              &::placeholder {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-transform: uppercase; } }
            .wpcf7-acceptance {
              font-size: 10px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.22;
              letter-spacing: -0.25px;
              color: white;
              .wpcf7-list-item {
                margin-left: 0; }
              label {
                display: flex;
                justify-content: space-between;
                input {
                  width: 16px;
                  height: 16px;
                  margin-right: 16px; }
                span {
                  width: calc(100% - 32px); } } } }
          .wpcf7-submit {
            width: 46%;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 19.8px;
            border: 0;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
            background-color: $color-blue;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.63px;
            text-transform: uppercase;
            transition: .3s;
            color: white;
            @media only screen and (max-width: $r-768 - 1) {
              width: 100%; }
            &:hover {
              transition: .3s;
              text-decoration: none;
              box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
              background-color: $color-dark-blue;
              color: white; } } } } } } }

// Keyframes

@keyframes line-down {
  0% {
    top: 0;
    height: 0; }
  100% {
    height: 100%; } }

@keyframes line-left {
  0% {
    left: 50%;
    width: 0; }
  100% {
    left: 0;
    width: 50%; } }
@keyframes line-right {
  0% {
    right: 50%;
    width: 0; }
  100% {
    right: 0;
    width: 50%; } }

// Default content

.default-content {
  padding: 150px 0;
  .main-title {
    margin-bottom: 30px; } }
