// Header

.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 30px 0;
  transition: .3s;
  z-index: 10;
  &.sticky {
    box-shadow: 40px 40px 80px 0 rgba(0,0,0,.15);
    background-color: white;
    transition: .3s; }
  @media only screen and (max-width: $r-768 - 1) {
    padding: 20px 0; }
  &.active {
    background-color: white!important; }
  .row {
    align-items: center;
    div[class*="col-"] {
      position: initial; } }
  .logo {
    a {
      img {
        height: 32px;
        &:nth-of-type(2) {
          display: none; } } } }
  .hamburger {
    display: none;
    a {
      display: flex;
      &:hover {
        text-decoration: none; }
      img {
        &:nth-of-type(2) {
          display: none; } } }
    @media only screen and (max-width: $r-992) {
      display: flex;
      justify-content: flex-end; } }
  nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media only screen and (max-width: $r-992) {
      display: none;
      text-align: center;
      &.active {
        display: block;
        position: absolute;
        top: 72px;
        left: 0;
        width: 100%;
        background-color: white;
        padding: 50px 0; } }
    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      @media only screen and (max-width: $r-992) {
        flex-direction: column; }
      li {
        margin: 0 15px;
        @media only screen and (max-width: $r-992) {
          margin-bottom: 15px; }
        a {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: center;
          text-transform: uppercase;
          outline: none;
          transition: .3s;
          color: #9b9b9b;
          @media only screen and (max-width: $r-992) {
            font-size: 16px; }
          &:hover {
            transition: .3s;
            color: $color-dark-blue; } } } }
    .btn-blue {
      margin-left: 15px;
      @media only screen and (max-width: $r-992) {
        display: inline-flex;
        margin: 30px 0 0 0; } } } }
