// Blog unique

.page-template-page-blog {
  .insights {
    .wrapper {
      @media only screen and (max-width: $r-768 - 1) {
        flex-wrap: wrap;
        flex-direction: column; }
      div[class*='col-'] {
        @media only screen and (max-width: $r-768 - 1) {
          max-width: 100%;
          .item {
            margin-bottom: 15px; } } } }
    .item {
      img {
        @media only screen and (max-width: $r-768 - 1) {
          height: 220px; } }
      h3 {
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 20px; } }
      p {
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 18px; } } } } }

// Blog hero

.blog-hero {
  padding: 175px 0 70px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 103px 0 30px 0; }
  .main-title {
    padding-top: 95px;
    @media only screen and (max-width: $r-768 - 1) {
      padding-top: 0; } }
  p {
    max-width: 406px; }
  img {
    max-width: 420px; }
  .text {
    max-width: 850px; } }

// Article

.article {
  padding: 180px 0 90px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 103px 0 30px 0; }
  .author {
    padding-top: 265px;
    @media only screen and (max-width: $r-768 - 1) {
      padding-top: 0;
      display: none; }
    h3,
    h4 {
      font-family: 'Raleway', sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: 0.28px;
      text-transform: none;
      margin-bottom: 0;
      color: #5d5d5d; }
    h3 {
      font-weight: 600; } }
  &-content {
    border-left: dashed 1px #dbdbdb;
    padding-top: 80px;
    @media only screen and (max-width: $r-768 - 1) {
      padding-top: 0; }
    .main-title {
      margin-bottom: 19px; }
    .content {
      padding: 0 0 50px 15px;
      @media only screen and (max-width: $r-768 - 1) {
        border-left: dashed 1px #dbdbdb; }
      .author {
        display: none;
        @media only screen and (max-width: $r-768 - 1) {
          display: block;
          margin-bottom: 30px; }
        h3,
        h4 {
          font-family: 'Raleway', sans-serif;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.44;
          letter-spacing: 0.28px;
          text-transform: none;
          margin-bottom: 0;
          color: #5d5d5d;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 16px; } }
        h3 {
          font-weight: 600; } }
      .date {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.39;
        letter-spacing: normal;
        margin-bottom: 52px;
        position: relative;
        color: #999;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 16px;
          font-weight: normal;
          line-height: 1.44;
          letter-spacing: -0.2px;
          margin-bottom: 15px;
          color: #9b9b9b; }
        &:before {
          position: absolute;
          content: '';
          left: -31px;
          width: 1px;
          height: 100%;
          background-color: #48d9ff;
          @media only screen and (max-width: $r-768 - 1) {
            left: -16px; } } }
      h3 {
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: 0.31px;
        text-transform: uppercase;
        margin-bottom: 15px;
        color: #4a90e2; }
      p {
        margin-bottom: 30px; }
      img {
        max-width: 576px;
        height: auto;
        @media only screen and (max-width: $r-992) {
          max-width: 100%; } }
      figure {
        figcaption {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.63;
          letter-spacing: 0.23px;
          color: #5d5d5d; } }
      blockquote {
        padding-left: 30px;
        position: relative;
        p {
          font-size: 21px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.52;
          letter-spacing: 0.33px;
          color: #5d5d5d; }
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          content: '"';
          font-family: 'Helvetica Neue' !important;
          font-size: 45px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.71;
          letter-spacing: 0.43px;
          color: #46a0ff; } } } } }

// Blog new

.page-template-page-blog-new {
  .cta {
    display: none; } }

.blog-new-hero {
  padding-bottom: 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding-top: 136px; }
  div[class*="col-"] {
    @media only screen and (max-width: $r-768 - 1) {
      position: initial; } }
  .text {
    padding: 65px 0 25px 0;
    @media only screen and (max-width: $r-768 - 1) {
      padding: 0; }
    p {
      max-width: 100%; } }
  .main-title {
    @media only screen and (max-width: $r-768 - 1) {
      max-width: 225px;
      margin-bottom: 70px; } }
  img {
    @media only screen and (max-width: $r-768 - 1) {
      position: absolute;
      width: 50%;
      top: 90px;
      right: -15px; } } }

.blog-new-list {
  padding: 80px 0 0 0;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; }
    .slider-blog {
      overflow-x: hidden;
      .swiper-button-next,
      .swiper-button-prev {
        top: 7%;
        outline: none; } }
    &.slider {
      display: none;
      @media only screen and (max-width: $r-768 - 1) {
        display: block; }
      .swiper-button-next,
      .swiper-button-prev {
        background-image: none;
        width: auto;
        height: auto;
        margin: 0;
        svg {
          height: 35px; } }
      .swiper-button-prev {
        transform: rotate(180deg); } }
    .item {
      display: flex;
      justify-content: space-between;
      min-height: 631px;
      width: 51px;
      padding: 34px 0 20px 0;
      margin-bottom: 122px;
      border-left: solid 1px #48d9ff;
      background-color: white;
      //box-shadow: 40px 40px 80px 0 rgba(0, 0, 0, 0.15)
      overflow: hidden;
      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      cursor: pointer;
      position: relative;
      @media only screen and (max-width: $r-768 - 1) {
        width: 100%;
        padding: 20px 15px;
        flex-direction: column;
        box-shadow: 40px 40px 80px 0 rgba(0, 0, 0, 0.15);
        border-left: 0;
        margin-bottom: 0; }
      &.full-width {
        width: 100% !important;
        min-height: 0;
        height: 0;
        margin: 0;
        padding: 0!important;
        @media only screen and (max-width: $r-768 - 1) {
          display: none; }
        .item-left,
        .item-right {
          display: none!important; } }
      &.active {
        width: 491px;
        padding: 34px 0 20px 25px;
        background-color: white;
        flex-grow: 10000;
        transform: scale(1);
        transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
        @media only screen and (max-width: $r-768 - 1) {
          padding: 20px 15px; }
        .item-left {
          opacity: 1;
          transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95); }
        .item-right {
          width: auto;
          padding-right: 25px;
          transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
          @media only screen and (max-width: $r-768 - 1) {
            padding-right: 0; } } }
      &:last-of-type {
        border-right: solid 1px #48d9ff; }
      .item-left {
        transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
        width: 404px;
        min-width: 404px;
        max-width: 100%;
        opacity: 0;
        @media only screen and (max-width: $r-768 - 1) {
          order: 2;
          width: 100%;
          min-width: 100%;
          opacity: 1; }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          .show-desctop {
            @media only screen and (max-width: $r-768 - 1) {
              display: none; } }
          .show-mobile {
            display: none;
            @media only screen and (max-width: $r-768 - 1) {
              display: block; } }
          li {
            display: flex;
            @media only screen and (max-width: $r-768 - 1) {
              flex-wrap: wrap;
              margin-bottom: 22px; }
            h4 {
              font-family: 'Raleway', sans-serif;
              font-size: 18px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              margin-bottom: 0;
              padding-bottom: 25px;
              color: #171f52;
              &:first-of-type {
                min-width: 130px;
                max-width: 130px;
                border-right: solid 1px #d4d4d4; }
              &:nth-of-type(2) {
                width: calc(100% - 128px);
                padding-left: 14px; } }
            p {
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.62;
              letter-spacing: -0.4px;
              margin-bottom: 0;
              padding: 0 0 16px 14px;
              color: #383838;
              @media only screen and (max-width: $r-768 - 1) {
                width: 100%;
                padding: 0; }
              b, strong {
                font-weight: 700;
                color: #171f52; }
              &:first-of-type {
                min-width: 130px;
                max-width: 130px;
                border-right: solid 1px #d4d4d4;
                letter-spacing: -0.2px;
                padding-left: 0;
                color: #383838;
                @media only screen and (max-width: $r-768 - 1) {
                  max-width: 100%;
                  min-width: 100%;
                  border-right: 0;
                  margin-bottom: 5px; } } }
            ul {
              width: calc(100% - 128px);
              padding-left: 14px;
              list-style: disc;
              margin-bottom: 16px;
              @media only screen and (max-width: $r-768 - 1) {
                width: 100%; }
              li {
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.62;
                letter-spacing: -0.4px;
                margin-bottom: 0;
                padding: 0 0 5px 10px;
                position: relative;
                color: #383838;
                &:before {
                  position: absolute;
                  content: '';
                  left: 0;
                  top: 7px;
                  width: 4px;
                  height: 4px;
                  background-color: #383838;
                  border-radius: 50%; } } } } } }
      .item-right {
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 51px;
        height: 100%;
        background-color: white;
        transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
        @media only screen and (max-width: $r-768 - 1) {
          order: 1;
          width: 100%;
          position: initial;
          margin: 0 0 42px 0; }
        .number {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 34px;
          height: 34px;
          margin-bottom: 15px;
          background-color: #171f52;
          border-radius: 50%;
          font-family: 'Raleway', sans-serif;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: white;
          @media only screen and (max-width: $r-768 - 1) {
            width: 69px;
            height: 69px;
            font-size: 34px;
            line-height: 0.79;
            margin-bottom: 50px; } }
        h3 {
          writing-mode: vertical-rl;
          text-orientation: mixed;
          font-family: 'Raleway', sans-serif;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          margin: 0;
          color: #171f52;
          @media only screen and (max-width: $r-768 - 1) {
            writing-mode: initial;
            font-size: 24px;
            line-height: 1.3; } } } } } }

// Blog form

.blog-form {
  background-image: radial-gradient(circle at 50% 4%, #1283c6, #023160 50%);
  padding: 90px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 70px 0 85px 0; }
  .title-secondary {
    color: white;
    @media only screen and (max-width: $r-768 - 1) {
      font-size: 60px;
      line-height: 64px; } }
  p {
    margin-bottom: 30px;
    color: white; }
  .btn-blue {
    display: inline-flex;
    min-width: 258px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 115px; } }
  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .ajax-loader {
      display: none; }
    .wpcf7-not-valid-tip {
      position: absolute;
      font-size: 12px; }
    .wpcf7-response-output {
      width: 100%;
      margin: 0;
      color: white;
      font-size: 12px;
      border: 1px solid white;
      border-radius: 4.8px; }
    .wpcf7-form-control-wrap {
      width: 100%;
      max-width: 444px;
      margin-bottom: 30px;
      input[type="text"],
      input[type="email"] {
        width: 100%;
        height: 45px;
        border-radius: 4.8px;
        border: solid 0.5px #979797;
        background-color: white;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        padding: 13px 10px;
        outline: none;
        appearance: none;
        resize: none;
        color: #535353;
        &::placeholder {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-transform: uppercase;
          color: #9b9b9b; } } }
    .wpcf7-submit {
      width: 100%;
      max-width: 444px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 19.8px;
      border: 0;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
      background-color: $color-blue;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.63px;
      text-transform: uppercase;
      transition: .3s;
      color: white;
      @media only screen and (max-width: $r-768 - 1) {
        width: 100%;
        margin-bottom: 0; }
      &:hover {
        transition: .3s;
        text-decoration: none;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        background-color: $color-dark-blue;
        color: white; } } } }
