// Technology unique

.page-id-101 {
  background-image: linear-gradient(to bottom, #1283c6 -5%, #117ec0 15%, #023160 60%);
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 600px;
    background-image: url("../img/header_technology_darker.png");
    background-size: cover; }
  .header {
    &.sticky {
      background-color: #023160; }
    &.active {
      nav {
        ul {
          li {
            a {
              color: #9b9b9b; } } } }
      .logo {
        a {
          img {
            &:first-of-type {
              display: block; }
            &:nth-of-type(2) {
              display: none; } } } }
      .hamburger {
        a {
          img {
            &:first-of-type {
              display: block; }
            &:nth-of-type(2) {
              display: none; } } } } }
    nav {
      ul {
        li {
          a {
            color: white; } } } }
    .logo {
      a {
        img {
          &:first-of-type {
            display: none; }
          &:nth-of-type(2) {
            display: block; } } } }
    .hamburger {
      a {
        img {
          &:first-of-type {
            display: none; }
          &:nth-of-type(2) {
            display: block; } } } } }
  .scale-with {
    .title-secondary {
      color: white; }
    .wrapper {
      div[class*='col-'] {
        &:nth-last-child(-n+4) {
          padding-bottom: 150px;
          @media only screen and (max-width: $r-768 - 1) {
            padding-bottom: 0; } }
        &:last-of-type {
          @media only screen and (max-width: $r-768 - 1) {
            padding-bottom: 50px; } } }
      .item {
        padding-top: 260px;
        @media only screen and (max-width: $r-768 - 1) {
          padding-top: 0; }
        p {
          color: white; }
        #lottiesafe,
        #lottieramp,
        #lottiecloud,
        #lottiegain,
        #lottieextract,
        #lottieleverage {
          width: 33px; } } } }
  .insights-top {
    padding-top: 0;
    margin-top: -60px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-top: 0;
      padding-top: 40px; }
    .title-secondary,
    p {
      color: white; } }
  .insights {
    .wrapper {
      .item {
        &:hover {
          box-shadow: 40px 40px 80px 0 rgba(0, 0, 0, 0.15);
          background-color: #0b629d; }
        h3,
        .date,
        p {
          color: white; } } } }
  .scale-with {
    .wrapper {
      div[class*='col-'] {
        &.start {
          &:before {
            border-left: dashed 1px rgba(219, 219, 219, 0.7); } }
        @media only screen and (max-width: $r-768 - 1) {
          border-left: 0; } }
      div[class*='col-']:nth-child(4n) {
        &.start {
          &:after {
            border-right: dashed 1px rgba(219, 219, 219, 0.7); } }
        @media only screen and (max-width: $r-768 - 1) {
          border-left: 0; } } } } }

// Technology hero

.technology-hero {
  padding: 195px 0 165px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 103px 0 45px 0; }
  .row {
    div[class*="col-"] {
      &:first-of-type {
        @media only screen and (max-width: $r-768 - 1) {
          order: 2;
          position: absolute;
          img {
            width: 45%;
            position: absolute;
            right: 0; } } }
      &:nth-of-type(2) {
        @media only screen and (max-width: $r-768 - 1) {
          order: 1;
          max-width: 100%; } } } }
  .main-title {
    color: white;
    margin-bottom: 15px;
    padding-top: 63px;
    padding-left: 30px;
    @media only screen and (max-width: $r-768 - 1) {
      padding-top: 0;
      padding-left: 0;
      width: 55%; } }
  p {
    max-width: 500px;
    padding-left: 30px;
    color: white;
    @media only screen and (max-width: $r-768 - 1) {
      max-width: 315px;
      padding-left: 0; }
    &:last-of-type {
      margin-bottom: 65px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 24px; } } }
  .btn-transparent {
    display: inline-flex;
    border-color: white;
    margin-left: 30px;
    color: white;
    @media only screen and (max-width: $r-768 - 1) {
      margin-left: 0; }
    &:hover {
      border-color: $color-dark-blue; } }
  img {
    max-width: 406px; } }

// Text block

.text-block {
  padding: 90px 0 15px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 45px 0 15px 0; }
  .title-secondary {
    margin-bottom: 23px;
    color: white; }
  p {
    max-width: 408px;
    color: white; } }

// Goals

.goals {
  padding: 30px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 55px 0 30px 0; }
  div[class*="col-"] {
    padding: 164px 15px 130px 15px;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      height: 100%; }
    &.start {
      &:before {
        border-right: dashed 1px #aeaeae;
        animation: line-down 2s ease-in-out; } }
    @media only screen and (max-width: $r-768 - 1) {
      padding: 0 15px;
      border-right: 0; }
    &:first-of-type {
      &:after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 100%; }
      &.start {
        &:after {
          border-left: dashed 1px #aeaeae;
          animation: line-down 2s ease-in-out; } }
      @media only screen and (max-width: $r-768 - 1) {
        border-left: 0;
        .item {
          border-left: 0;
          padding: 0 0 30px 0; } }
      p {
        font-size: 18px; } }
    &:last-of-type {
      @media only screen and (max-width: $r-768 - 1) {
        .item {
          padding-bottom: 0; } } }
    .item {
      padding: 0 10px;
      position: relative;
      @media only screen and (max-width: $r-768 - 1) {
        border-left: dashed 1px #aeaeae;
        padding: 0 0 5px 20px; }
      .title-secondary {
        margin-bottom: 15px;
        color: white; }
      h3 {
        display: flex;
        align-items: center;
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.41px;
        margin-bottom: 3px;
        position: relative;
        color: #48d9ff;
        @media only screen and (max-width: $r-768 - 1) {
          font-weight: 400; }
        &:before {
          position: absolute;
          content: '';
          left: -26px;
          width: 1px;
          height: 80%;
          background-color: #48d9ff;
          @media only screen and (max-width: $r-768 - 1) {
            left: -21px; } } }
      h4 {
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 1px;
        color: #47d8fd; }
      p {
        font-size: 16px;
        color: white; } } } }

// Features

.features {
  padding: 50px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 40px 0 40px 0; }
  .title-secondary {
    margin-bottom: 50px;
    color: white;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 30px; } }
  .row {
    @media only screen and (max-width: $r-1200) {
      overflow-x: scroll; } }
  .flex {
    display: flex;
    justify-content: space-between;
    .item {
      width: 18%;
      @media only screen and (max-width: $r-1200) {
        min-width: 200px;
        width: auto;
        margin-right: 25px; }
      &:first-of-type {
        h4 {
          background-image: url("../img/bg-tech-feature-1.svg"); } }
      &:nth-of-type(2) {
        h4 {
          background-image: url("../img/bg-tech-feature-2.svg"); } }
      &:nth-of-type(3) {
        h4 {
          background-image: url("../img/bg-tech-feature-3.svg"); } }
      &:nth-of-type(4) {
        h4 {
          background-image: url("../img/bg-tech-feature-4.svg"); } }
      &:nth-of-type(5) {
        h4 {
          background-image: url("../img/bg-tech-feature-5.svg"); } }
      h3 {
        border-radius: 9px;
        background-color: #f3dc88;
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.01px;
        margin-bottom: 10px;
        text-transform: none;
        text-align: center;
        padding: 4px 5px;
        color: #000036; }
      h4 {
        height: 83px;
        background-size: cover;
        background-repeat: no-repeat;
        font-family: 'Raleway', sans-serif;
        font-size: 17px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.01px;
        margin-bottom: 25px;
        text-transform: none;
        text-align: center;
        padding: 11px 5px 4px 5px;
        color: white; }
      img {
        height: 200px;
        object-fit: cover;
        border-right: 8px;
        margin-bottom: 10px; }
      p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: 0.16px;
        text-align: center;
        color: #fff; } } } }


// List block

.list-block {
  padding: 50px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 40px 0; }
  .title-secondary {
    margin-bottom: 30px;
    color: white;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 20px; } }
  p {
    max-width: 673px;
    color: white;
    padding-bottom: 30px;
    @media only screen and (max-width: $r-768 - 1) {
      padding-bottom: 30px; } }
  .line {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 78px;
    &.start {
      .line-left {
        position: absolute;
        left: 0;
        width: 50%;
        border-bottom: dashed 1px #1283c6;
        animation: line-left 2s ease-in-out; }
      .line-right {
        position: absolute;
        right: 0;
        width: 50%;
        border-bottom: dashed 1px #1283c6;
        animation: line-right 2s ease-in-out; } }
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 40px; } }
  .wrapper {
    .item {
      margin-bottom: 30px;
      h3 {
        display: flex;
        align-items: center;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.87;
        letter-spacing: 0.25px;
        margin-bottom: 34px;
        color: white;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 22px;
          line-height: 2.55;
          letter-spacing: 0.25px;
          margin-bottom: 20px; }
        lottie-player {
          display: flex;
          height: 43px;
          width: 43px;
          margin-right: 21px;
          @media only screen and (max-width: $r-768 - 1) {
            height: 33px;
            width: 33px; } }
        .animation {
          overflow: visible; } }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          font-size: 16px;
          position: relative;
          padding-left: 31px;
          margin-bottom: 12px;
          color: white;
          &:before {
            position: absolute;
            content: '';
            background-image: url("../img/check-mark-white.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 20px;
            height: 20px;
            left: 0; } } } } } }

