// Footer

.footer {
  background-color: white;
  padding: 35px 0 40px;
  .flex {
    display: flex;
    align-items: center;
    @media only screen and (max-width: $r-992) {
      justify-content: space-between; }
    .logo {
      margin-right: 30px; }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      li {
        margin-right: 10px; } } }
  .socials {
    li {
      a {
        width: 31px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #9b9b9b;
        border-radius: 50px;
        &:hover {
          text-decoration: none; }
        i {
          color: white; } } } }
  nav {
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      li {
        margin-left: 30px;
        @media only screen and (max-width: $r-992) {
          margin-left: 20px; }
        a {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          transition: .3s;
          color: #9b9b9b;
          &:hover {
            transition: .3s;
            color: $color-dark-blue; } } } } } }
