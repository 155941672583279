// Home hero

.home-hero {
  padding: 255px 0 225px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0;
    min-height: 520px;
    .container {
      position: absolute;
      padding-top: 103px; } }
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/fade_above_animation.png");
    background-size: contain; }
  @media only screen and (max-width: $r-768 - 1) {
    &:before {
      background-image: url("../img/mobile_transperent.png");
      background-repeat: no-repeat; } }
  .bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; }
    &:before {
      position: absolute;
      content: '';
      width: calc(100% - 90px);
      height: 100%;
      background-image: linear-gradient(137deg, rgba(255, 255, 255, 0.89) 51%, rgba(255, 255, 255, 0) 97%);
      opacity: 0.6;
      z-index: -1; } }
  .bg-mobile {
    display: none;
    width: 100%;
    height: 382px;
    object-fit: cover;
    position: absolute;
    top: 0;
    z-index: -2;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  lottie-player {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: -105px;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  lottie-player {
    &:nth-of-type(2) {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      z-index: -1;
      @media only screen and (max-width: $r-768 - 1) {
        display: block;
        position: initial; } } }
  h1 {
    max-width: 520px;
    margin-bottom: 5px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 13px; } }
  p {
    max-width: 485px;
    margin-bottom: 65px;
    @media only screen and (max-width: $r-768 - 1) {
      max-width: 315px;
      margin-bottom: 30px;
      color: #4a4a4a; } }
  .btn-transparent {
    display: inline-flex; } }

// Home video

.home-video {
  padding: 0 0 60px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 40px 0 50px 0; }
  .title-secondary {
    text-align: center;
    max-width: 765px;
    margin: 0 auto 15px auto;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 19px;
      text-align: left; } }
  p {
    text-align: center;
    max-width: 585px;
    margin: 0 auto;
    &:last-of-type {
      margin-bottom: 100px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 30px;
        text-align: left; } } }
  .video {
    max-width: 675px;
    margin: 0 auto;
    iframe {
      box-shadow: 17px 17px 22px 3px rgba(0, 0, 0, 0.16);
      @media only screen and (max-width: $r-768 - 1) {
        height: 180px; } } }
  .lines {
    background-image: url("../img/home_lines.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 64px;
    height: 50px;
    width: 100%;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } } }

// Home solutions

.home-solutions {
  padding: 70px 0 20px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 50px 0 30px 0; }
  .title-secondary {
    max-width: 675px;
    margin-bottom: 12px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 19px; } }
  p {
    max-width: 775px;
    &:last-of-type {
      margin-bottom: 35px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 30px; } } }
  .wrapper {
    .line {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 40px;
      &.start {
        .line-left {
          position: absolute;
          left: 0;
          width: 50%;
          border-bottom: dashed 1px #1283c6;
          animation: line-left 2s ease-in-out; }
        .line-right {
          position: absolute;
          right: 0;
          width: 50%;
          border-bottom: dashed 1px #1283c6;
          animation: line-right 2s ease-in-out; } }
      @media only screen and (max-width: $r-768 - 1) {
        padding-top: 30px; } }
    div[class*="col-"] {
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: -10px;
        width: 1px;
        height: 100%; }
      &.start {
        &:before {
          border-left: dashed 1px #dbdbdb;
          animation: line-down 2s ease-in-out; }
        &:after {
          border-right: dashed 1px #dbdbdb;
          animation: line-down 2s ease-in-out; } }
      &:last-of-type {
        &:after {
          position: absolute;
          content: '';
          top: 0;
          right: -25px;
          width: 1px;
          height: 100%; } }
      &:nth-of-type(2) {
        .item {
          @media only screen and (max-width: $r-768 - 1) {
            border-bottom: dashed 1px #1283c6;
            margin-bottom: 30px; }
          h3 {
            lottie-player {
              width: 37px;
              height: 43px; } } } }
      &:nth-of-type(3) {
        .item {
          h3 {
            lottie-player {
              width: 66px;
              height: 46px; } } } } }
    .item {
      position: relative;
      padding: 45px 0;
      @media only screen and (max-width: $r-768 - 1) {
        padding: 0; }
      h3 {
        min-height: 46px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.87;
        letter-spacing: 0.25px;
        color: $color-blue;
        @media only screen and (max-width: $r-768 - 1) {
          font-weight: 400;
          margin-bottom: 30px; }
        lottie-player {
          display: flex;
          height: 43px;
          width: 43px;
          margin-right: 13px;
          .animation {
            overflow: visible; } } }
      ul {
        list-style: none;
        margin: 0 0 29px 0;
        padding: 0;
        @media only screen and (max-width: $r-768 - 1) {
          margin-bottom: 30px; }
        li {
          position: relative;
          padding: 0 0 0 31px;
          margin-bottom: 11px;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 15px; }
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 2px;
            background-image: url("../img/check-icon.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 20px;
            height: 20px; } } }
      p {
        @media only screen and (max-width: $r-768 - 1) {
          text-align: left;
          max-width: 90%; } } } } }

// Work with

.work-with {
  background-image: linear-gradient(to bottom, rgba(0, 238, 255, 0.07), rgba(255, 255, 255, 0));
  padding: 110px 0 0 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 45px 0 20px 0; }
  .title-secondary {
    text-align: center;
    margin-bottom: 15px;
    @media only screen and (max-width: $r-768 - 1) {
      text-align: left;
      margin-bottom: 19px; } }
  p {
    max-width: 410px;
    text-align: center;
    margin: 0 auto;
    @media only screen and (max-width: $r-768 - 1) {
      text-align: left; }
    &:last-of-type {
      margin-bottom: 50px; } }
  .wrapper {
    position: relative;
    &:before {
      display: none;
      position: absolute;
      bottom: 40px;
      left: 15px;
      content: '';
      width: calc(100% - 30px);
      border-top: dashed 1px #1283c6;
      @media only screen and (max-width: $r-768 - 1) {
        display: block; } }
    @media only screen and (max-width: $r-768 - 1) {
      flex-wrap: nowrap;
      flex-direction: row;
      overflow-x: scroll; }
    .line {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      &.start {
        .line-left {
          position: absolute;
          left: 0;
          width: 50%;
          border-bottom: dashed 1px #1283c6;
          animation: line-left 2s ease-in-out; }
        .line-right {
          position: absolute;
          right: 0;
          width: 50%;
          border-bottom: dashed 1px #1283c6;
          animation: line-right 2s ease-in-out; } } }
    div[class*="col-"] {
      position: relative;
      padding-bottom: 145px;
      &:before {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        height: 100%; }
      &:first-of-type {
        .item {
          h3 {
            &:before {
              left: -20px; } } } }
      &.start {
        &:before {
          border-right: dashed 1px #dbdbdb;
          animation: line-down 2s ease-in-out; } }
      @media only screen and (max-width: $r-768 - 1) {
        border-left: 0;
        padding-bottom: 105px;
        max-width: 300px; }
      &:first-of-type {
        &:after {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 100%; }
        &.start {
          &:after {
            border-left: dashed 1px #dbdbdb;
            animation: line-down 2s ease-in-out; } }
        @media only screen and (max-width: $r-768 - 1) {
          border-right: 0; } }
      &:last-of-type {
        padding-bottom: 0; } }
    .item {
      padding: 0 5px;
      @media only screen and (max-width: $r-768 - 1) {
        height: 100%;
        text-align: center;
        border-radius: 5px;
        box-shadow: 40px 40px 80px 0 rgba(0, 0, 0, 0.15);
        background-color: white;
        padding: 25px; }
      img {
        height: 120px;
        object-fit: cover;
        margin-bottom: 20px;
        @media only screen and (max-width: $r-768 - 1) {
          margin-bottom: 15px; } }
      h3 {
        min-height: 54px;
        font-family: 'Raleway', sans-serif;
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        margin-bottom: 18px;
        text-transform: uppercase;
        position: relative;
        color: #171f52;
        @media only screen and (max-width: $r-992) {
          font-size: 18px;
          min-height: 69px; }
        @media only screen and (max-width: $r-768 - 1) {
          min-height: 46px; }
        &:before {
          position: absolute;
          content: '';
          left: -21px;
          top: 0;
          width: 1px;
          height: 45px;
          background-color: #48d9ff;
          z-index: 1;
          @media only screen and (max-width: $r-768 - 1) {
            display: none; } } }
      p {
        font-size: 16px;
        line-height: 1.63;
        letter-spacing: 0.25px;
        text-align: left;
        margin: 0 auto 16px auto;
        @media only screen and (max-width: $r-992) {
          max-width: 160px;
          text-align: center; } } } } }

.scale-with {
  .title-secondary {
    @media only screen and (max-width: $r-992) {
      font-size: 34px; } }
  .wrapper {
    div[class*="col-"] {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        left: -1px;
        top: 0;
        height: 100%;
        @media only screen and (max-width: $r-768 - 1) {
          display: none; } }
      &:first-of-type {
        &:before {
          left: 0; } }
      &:nth-of-type(5) {
        &:before {
          left: 0; }
        .item {
          .icon {
            &:before {
              left: -20px; } } } }
      &.start {
        &:before {
          border-left: dashed 1px #dbdbdb;
          animation: line-down 2s ease-in-out; } }
      @media only screen and (max-width: $r-768 - 1) {
        border-left: 0; }
      &:first-of-type {
        @media only screen and (max-width: $r-768 - 1) {
          border-left: 0; }
        .item {
          @media only screen and (max-width: $r-768 - 1) {
            padding: 0;
            margin-bottom: 30px;
            border-left: 0; } } }
      &:nth-child(4n) {
        &:after {
          position: absolute;
          content: '';
          right: 0;
          top: 0;
          height: 100%;
          @media only screen and (max-width: $r-768 - 1) {
            display: none; } }
        &.start {
          &:after {
            border-right: dashed 1px #dbdbdb;
            animation: line-down 2s ease-in-out; } }
        @media only screen and (max-width: $r-768 - 1) {
          border-right: 0; } }
      &:nth-child(n+5) {
        .item {
          padding-top: 0; } }
      &:last-of-type {
        .item {
          padding-bottom: 0; } } }
    .item {
      padding: 145px 5px 0 5px;
      margin-bottom: 30px;
      @media only screen and (max-width: $r-768 - 1) {
        padding: 0 0 40px 20px;
        margin-bottom: 0;
        border-left: dashed 1px #dbdbdb; }
      .icon {
        position: relative;
        margin-bottom: 7px;
        height: 43px;
        width: 33px;
        svg {
          width: 33px;
          height: 33px; }
        &:before {
          position: absolute;
          content: '';
          left: -21px;
          top: 0;
          width: 1px;
          height: 33px;
          background-color: #48d9ff; } }
      p {
        min-height: 150px;
        font-size: 16px;
        line-height: 1.63;
        letter-spacing: 0.25px;
        @media only screen and (max-width: $r-768 - 1) {
          min-height: auto;
          margin-bottom: 0; } } } } }

// Home testimonials

.home-testimonials {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 238, 255, 0.08));
  padding: 0 0 90px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding-bottom: 60px; }
  .swiper-testimonials {
    padding: 90px 0 115px 0;
    .swiper-pagination {
      .swiper-pagination-bullet {
        width: 16px;
        height: 16px;
        background-color: rgba(155, 155, 155, 0.65);
        margin: 0 10px;
        opacity: 1; }
      .swiper-pagination-bullet-active {
          background-color: #171c34; } }
    .swiper-slide {
      width: 100%;
      .item {
        box-shadow: 40px 40px 80px 0 rgba(0, 0, 0, 0.15);
        max-width: 1080px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 28px 164px 51px 163px;
        border-radius: 5px;
        background-color: white;
        @media only screen and (max-width: $r-768 - 1) {
          padding: 25px 11px;
          max-width: calc(100% - 40px); }
        .logo {
          max-width: 207px;
          @media only screen and (max-width: $r-768 - 1) {
            margin-bottom: 15px;
            max-width: 100px; } }
        .quote {
          margin-top: -30px;
          @media only screen and (max-width: $r-768 - 1) {
            width: 80px; } }
        p {
          max-width: 530px;
          text-align: center;
          font-size: 16px;
          line-height: 1.63;
          letter-spacing: -0.4px;
          &:last-of-type {
            margin-bottom: 50px; } }
        h3,
        h4 {
          font-family: 'Raleway', sans-serif;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.3;
          letter-spacing: -0.5px;
          text-align: center;
          margin-bottom: 5px;
          color: #9b9b9b; }
        h4 {
          text-transform: uppercase; } } } } }

// Insights

.insights-top {
  padding-top: 80px;
  @media only screen and (max-width: $r-768 - 1) {
    padding-top: 45px; }
  p {
    max-width: 775px; } }

.insights {
  padding: 80px 0 0 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 40px 0; }
  .title-secondary {
    margin-bottom: 15px; }
  p {
    &:last-of-type {
      margin-bottom: 75px; } }
  .wrapper {
    @media only screen and (max-width: $r-768 - 1) {
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: scroll; }
    div[class*="col-"] {
      @media only screen and (max-width: $r-768 - 1) {
        max-width: 300px; } }
    .item {
      display: flex;
      flex-direction: column;
      transition: .3s;
      margin-bottom: 100px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 0; }
      &:hover {
        transition: .3s;
        border-radius: 5px;
        box-shadow: 40px 40px 80px 0 rgba(0, 0, 0, 0.15);
        background-color: white;
        text-decoration: none; }
      img {
        height: 220px;
        width: 100%;
        object-fit: cover;
        margin-bottom: 20px;
        @media only screen and (max-width: $r-992) {
          height: 177px; } }
      h3 {
        font-family: 'Raleway', sans-serif;
        padding-left: 24px;
        min-height: 54px;
        max-width: 200px;
        border-left: solid 1px #48d9ff;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: normal;
        margin-bottom: 3px;
        color: $color-dark-blue;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 18px; } }
      .date {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: 0.22px;
        margin-bottom: 10px;
        padding: 0 24px;
        color: #aeaeae; }
      p {
        padding: 0 24px 24px 24px;
        margin: 0 0 16px 0; } } } }

// CTA

.cta {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: $r-768 - 1) {
    flex-direction: column; }
  .container {
    position: absolute;
    z-index: 1; }
  .title-secondary {
    font-size: 75px;
    line-height: 65px;
    max-width: 335px;
    text-align: center;
    margin: 0 auto 20px auto;
    color: white;
    @media only screen and (max-width: $r-768 - 1) {
      max-width: 260px;
      font-size: 45px;
      line-height: 1.07;
      letter-spacing: normal;
      margin-bottom: 15px; } }
  p {
    text-align: center;
    color: white;
    &:last-of-type {
      margin-bottom: 36px; } }
  .btn-blue {
    display: inline-flex; }
  lottie-player {
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  lottie-player {
    &:nth-of-type(2) {
      display: none;
      @media only screen and (max-width: $r-768 - 1) {
        display: block;
        position: initial; } } }
  .bg {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } } }
